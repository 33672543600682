<template>
  <b-sidebar
    id="edit-new-asset-sidebar"
    :visible="isEditNewAssetSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-edit-new-asset-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit Asset
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          size="lg"
          @submit.prevent="handleSubmit(onSubmit)"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
              size="lg"
            >
              <b-form-input
                id="name"
                v-model="assetData.name"
                autofocus
                :state="getValidationState(validationContext)"
                placeholder="Main Drive Motor"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Device -->
          <validation-provider
            #default="validationContext"
            name="Device"
            rules="required"
          >
            <b-form-group
              label="Device"
              label-for="deviceID"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="assetData.deviceID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="devicesList"
                label="deviceID"
                :reduce="val => val.deviceID"
                :clearable="false"
                input-id="deviceID"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Customer -->
          <validation-provider
            #default="validationContext"
            name="Customer"
            rules="required"
          >
            <b-form-group
              label="Customer"
              label-for="customerID"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="assetData.customerID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="customerList"
                label="name"
                :reduce="val => val.customerID"
                :clearable="false"
                input-id="customerID"
                :disabled="!allowCustomerSelection"
                @input="onCustomerSelected"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Site -->
          <validation-provider
            #default="validationContext"
            name="Site"
            rules="required"
          >
            <b-form-group
              label="Site"
              label-for="siteID"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="assetData.siteID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="siteList"
                label="name"
                :reduce="val => val.id"
                :clearable="false"
                input-id="siteID"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Building -->
          <validation-provider
            #default="validationContext"
            name="Building"
            rules="required"
          >
            <b-form-group
              label="Building"
              label-for="buildingID"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="assetData.buildingID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="buildingList"
                label="name"
                :reduce="val => val.id"
                :clearable="false"
                input-id="buildingID"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Line -->
          <validation-provider
            #default="validationContext"
            name="Line"
            rules="required"
          >
            <b-form-group
              label="Line"
              label-for="lineID"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="assetData.lineID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="lineList"
                label="name"
                :reduce="val => val.id"
                :clearable="false"
                input-id="lineID"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Schematic ID"
            rules="required"
          >
            <b-form-group
              label="Schematic ID"
              label-for="schematicID"
            >
              <b-form-input
                id="schematicID"
                v-model="assetData.schematicID"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="1"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="whatsapp"
          >
            <b-form-group
              label="Notification"
              label-for="schematicID"
            >
              <b-form-checkbox
                id="whatsapp"
                v-model="assetData.whatsapp"
                :state="getValidationState(validationContext)"
              >WhatsApp
              </b-form-checkbox>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Save Changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import {
  getUserData,
} from '@/api/auth/utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import customer from '@/store/customer'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditNewAssetSidebarActive',
    event: 'update:is-edit-new-asset-sidebar-active',
  },
  props: {
    isEditNewAssetSidebarActive: {
      type: Boolean,
      required: true,
    },
    assetData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-customer'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customer)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()
    const userData = getUserData()
    const allowCustomerSelection = userData.claims.includes('customer:all')

    const devicesList = ref([{ deviceID: 'None', name: 'None' }])
    const customerList = ref([])
    const siteList = ref([])
    const buildingList = ref([])
    const lineList = ref([])

    const fetchAllDevices = () => {
      store.dispatch('app-assets-list/fetchDevicesByStatus', 'tested')
        .then(response => {
          const tempList = response.data
          tempList.unshift({ deviceID: 'None', name: 'None' })
          devicesList.value = tempList
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error in Fetching Device',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const onSubmit = () => {
      store
        .dispatch('app-assets-list/updateAsset', props.assetData)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Asset updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          emit('refetch-data')
          emit('update:is-edit-new-asset-sidebar-active', false)
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating asset',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const fetchAllCustomers = () => {
      store.dispatch('app-customer/fetchAllCustomers')
        .then(response => {
          customerList.value = response.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error in Fetching Customers',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const fetchAllLines = () => {
      store.dispatch('factory/fetchAllLines')
        .then(response => {
          const { data } = response
          if (props.assetData.customerID) {
            lineList.value = data.filter(line => line.customerID === props.assetData.customerID)
          } else {
            lineList.value = data
          }
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error in Fetching Lines',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const fetchAllSites = () => {
      store.dispatch('factory/fetchAllSites')
        .then(response => {
          const { data } = response
          if (props.assetData.customerID) {
            siteList.value = data.filter(site => site.customerID === props.assetData.customerID)
          } else {
            siteList.value = data
          }
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error in Fetching Sites',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const fetchAllBuildings = () => {
      store.dispatch('factory/fetchAllBuildings')
        .then(response => {
          const { data } = response
          if (props.assetData.customerID) {
            buildingList.value = data.filter(building => building.customerID === props.assetData.customerID)
          } else {
            buildingList.value = data
          }
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error in Fetching Buildings',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const onCustomerSelected = customerID => {
      if (customer) {
        // eslint-disable-next-line no-param-reassign
        props.assetData.lineID = ''
        // eslint-disable-next-line no-param-reassign
        props.assetData.siteID = ''
        // eslint-disable-next-line no-param-reassign
        props.assetData.buildingID = ''
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    fetchAllDevices()
    fetchAllCustomers()
    fetchAllLines()
    fetchAllSites()
    fetchAllBuildings()

    return {
      onSubmit,
      fetchAllDevices,
      devicesList,
      customerList,
      siteList,
      buildingList,
      lineList,
      refFormObserver,
      getValidationState,
      resetForm,
      allowCustomerSelection,
      onCustomerSelected,
    }
  },
}
</script>

    <style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';

    #add-new-user-sidebar {
      .vs__dropdown-menu {
        max-height: 200px !important;
      }
    }
    </style>
