<template>
  <b-sidebar
    id="add-new-asset-sidebar"
    :visible="isAddNewAssetSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-asset-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Asset
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <factory-list-add-new
        :is-add-new-factory-sidebar-active.sync="isAddNewFactorySidebarActive"
        @refetch-data="fetchAllFactory"
      />
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          size="lg"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
              size="lg"
            >
              <b-form-input
                id="name"
                v-model="assetData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Main Drive Motor"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Device -->
          <validation-provider
            #default="validationContext"
            name="Device"
            rules="required"
          >
            <b-form-group
              label="Device"
              label-for="deviceID"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="assetData.deviceID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="devicesList"
                label="deviceID"
                :reduce="val => val.deviceID"
                :clearable="false"
                input-id="deviceID"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group
            label="Template"
            label-for="template"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="templateOptions"
              label="assetID"
              :clearable="true"
              input-id="template"
              @input="onTemplateSelected"
            >
              <template v-slot:option="option">
                {{ option.assetID }}-{{ option.name }}
              </template>
            </v-select>
          </b-form-group>

          <!-- Customer -->
          <validation-provider
            #default="validationContext"
            name="Customer"
            rules="required"
          >
            <b-form-group
              label="Customer"
              label-for="customerID"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="assetData.customerID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="customerList"
                label="name"
                :reduce="val => val.customerID"
                :clearable="false"
                input-id="customerID"
                :disabled="!allowCustomerSelection"
                @input="onCustomerSelected"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Site -->
          <validation-provider
            #default="validationContext"
            name="Site"
            rules="required"
          >
            <b-form-group
              label="Site"
              label-for="siteID"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="assetData.siteID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="siteList"
                label="name"
                :reduce="val => val.id"
                :clearable="false"
                input-id="siteID"
              >
                <template #list-header>
                  <li style="text-align: center">
                    <b-link
                      active
                      class="font-weight-bold d-block text-nowrap m-1"
                      @click="isAddNewFactorySidebarActive=true"
                    >
                      Create new site
                    </b-link>
                  </li>
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Building -->
          <validation-provider
            #default="validationContext"
            name="Building"
            rules="required"
          >
            <b-form-group
              label="Building"
              label-for="buildingID"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="assetData.buildingID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="buildingList"
                label="name"
                :reduce="val => val.id"
                :clearable="false"
                input-id="buildingID"
              >
                <template #list-header>
                  <li style="text-align: center">
                    <b-link
                      active
                      class="font-weight-bold d-block text-nowrap m-1"
                      @click="isAddNewFactorySidebarActive=true"
                    >
                      Create new building
                    </b-link>
                  </li>
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Line -->
          <validation-provider
            #default="validationContext"
            name="Line"
            rules="required"
          >
            <b-form-group
              label="Line"
              label-for="lineID"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="assetData.lineID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="lineList"
                label="name"
                :reduce="val => val.id"
                :clearable="false"
                input-id="lineID"
              >
                <template #list-header>
                  <li style="text-align: center">
                    <b-link
                      active
                      class="font-weight-bold d-block text-nowrap m-1"
                      @click="isAddNewFactorySidebarActive=true"
                    >
                      Create new line
                    </b-link>
                  </li>
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Schematic ID"
            rules="required"
          >
            <b-form-group
              label="Schematic ID"
              label-for="schematicID"
            >
              <b-form-input
                id="schematicID"
                v-model="assetData.schematicID"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="1"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click="cloneAsset = true"
            >
              Add and Clone
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click="cloneAsset = false"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BLink,
} from 'bootstrap-vue'
import {
  getUserData,
} from '@/api/auth/utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import customer from '@/store/customer'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FactoryListAddNew from '../../factory/factory-list/FactoryListAddNew.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    FactoryListAddNew,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BLink,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewAssetSidebarActive',
    event: 'update:is-add-new-asset-sidebar-active',
  },
  props: {
    isAddNewAssetSidebarActive: {
      type: Boolean,
      required: true,
    },
    templateOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-customer'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customer)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()
    const userData = getUserData()
    const allowCustomerSelection = userData.claims.includes('customer:all')
    const isAddNewFactorySidebarActive = ref(false)

    const blankAssetData = {
      name: '',
      deviceID: 'None',
      schematicID: '0',
      lineID: '',
      customerID: userData.customerID,
      siteID: '',
      buildingID: '',
      status: 0,
      state: 0,
      template: 0,
    }

    const assetData = ref(JSON.parse(JSON.stringify(blankAssetData)))
    const resetAssetData = () => {
      assetData.value = JSON.parse(JSON.stringify(blankAssetData))
    }

    const devicesList = ref([{ deviceID: 'None', name: 'None' }])
    const customerList = ref([])
    const siteList = ref([])
    const buildingList = ref([])
    const lineList = ref([])
    const cloneAsset = ref(false)

    const fetchAllDevices = () => {
      store.dispatch('app-assets-list/fetchDevicesByStatus', 'tested')
        .then(response => {
          const tempList = response.data
          tempList.unshift({ deviceID: 'None', name: 'None' })
          devicesList.value = tempList
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error in Fetching Device',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const onTemplateSelected = template => {
      if (template) {
        assetData.value.lineID = template.lineID
        assetData.value.customerID = template.customerID
        assetData.value.siteID = template.siteID
        assetData.value.buildingID = template.buildingID
        assetData.value.template = template.assetID
      }
    }

    const onSubmit = () => {
      store.dispatch('app-assets-list/createNewAsset', assetData.value)
        .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: `Asset ${response.data.assetID} Created Successfully`,
              icon: 'CheckIcon',
              variant: 'Success',
            },
          })
          emit('refetch-data')
          if (!cloneAsset.value) {
            emit('update:is-add-new-asset-sidebar-active', false)
          }
        })
    }

    const fetchAllCustomers = () => {
      store.dispatch('app-customer/fetchAllCustomers')
        .then(response => {
          customerList.value = response.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error in Fetching Customers',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const fetchAllLines = () => {
      store.dispatch('factory/fetchAllLines')
        .then(response => {
          const { data } = response
          if (assetData.value.customerID) {
            lineList.value = data.filter(line => line.customerID === assetData.value.customerID)
          } else {
            lineList.value = data
          }
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error in Fetching Lines',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const fetchAllSites = () => {
      store.dispatch('factory/fetchAllSites')
        .then(response => {
          const { data } = response
          if (assetData.value.customerID) {
            siteList.value = data.filter(site => site.customerID === assetData.value.customerID)
          } else {
            siteList.value = data
          }
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error in Fetching Sites',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const fetchAllBuildings = () => {
      store.dispatch('factory/fetchAllBuildings')
        .then(response => {
          const { data } = response
          if (assetData.value.customerID) {
            buildingList.value = data.filter(building => building.customerID === assetData.value.customerID)
          } else {
            buildingList.value = data
          }
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error in Fetching Buildings',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const onCustomerSelected = customerID => {
      if (customer) {
        assetData.value.lineID = ''
        assetData.value.siteID = ''
        assetData.value.buildingID = ''
        fetchAllLines()
        fetchAllSites()
        fetchAllBuildings()
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetAssetData)

    fetchAllDevices()
    fetchAllCustomers()
    fetchAllLines()
    fetchAllSites()
    fetchAllBuildings()

    const fetchAllFactory = () => {
      fetchAllLines()
      fetchAllSites()
      fetchAllBuildings()
    }

    return {
      assetData,
      onSubmit,
      onTemplateSelected,
      onCustomerSelected,
      fetchAllDevices,
      devicesList,
      customerList,
      siteList,
      buildingList,
      lineList,
      cloneAsset,
      refFormObserver,
      getValidationState,
      resetForm,
      allowCustomerSelection,
      isAddNewFactorySidebarActive,
      fetchAllFactory,
    }
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
