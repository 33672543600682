<template>
  <div>
    <asset-list-add-new
      v-if="allowAssetManagement"
      :is-add-new-asset-sidebar-active.sync="isAddNewAssetSidebarActive"
      :template-options="items"
      @refetch-data="fetchAllAssets"
    />
    <asset-list-edit
      v-if="allowAssetManagement"
      :is-edit-new-asset-sidebar-active.sync="isEditNewAssetSidebarActive"
      :asset-data="selectedItem"
      @refetch-data="fetchAllAssets"
    />
    <b-card
      no-body
    >
      <b-card-body>
        <div class="custom-search d-flex justify-content-end">

          <!-- filter -->
          <b-form-select
            v-model="filter"
            :options="buildings"
            class="mb-0 mr-1 w-25"
            variant="primary"
            size="lg"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                Filter by Building
              </b-form-select-option>
            </template>
          </b-form-select>
          <b-form-select
            v-model="filter"
            :options="lines"
            class="mb-0 mr-1 w-25"
            variant="primary"
            size="lg"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                Filter by Line
              </b-form-select-option>
            </template>
          </b-form-select>
          <b-form-group
            class="mb-0"
          >
            <b-input-group size="lg">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
            </b-input-group>
          </b-form-group>
          <b-button
            v-if="allowAssetManagement"
            class="ml-1"
            variant="primary"
            @click="isAddNewAssetSidebarActive = true"
          >
            <span class="text-nowrap"><feather-icon icon="PlusIcon" />Add Asset</span>
          </b-button>
        </div>
      </b-card-body>

      <b-table
        striped
        hover
        responsive
        sticky-header="78vh"
        :no-border-collapse="true"
        :items="items"
        :fields="fields"
        primary-key="assetID"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(assetID)="data">
          <b-media vertical-align="center">
            <b-link
              active
              :to="{ name: 'apps-assets-log', params: { 'assetID': data.item.assetID } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.assetID }}
            </b-link>
          </b-media>
        </template>
        <template #cell(name)="data">
          <feather-icon
            class="mr-1"
            :class="`text-${statusMapping(data.item.status)}`"
            :fill="`${statusMappingColor(data.item.status)}`"
            icon="CircleIcon"
            size="20"
          />{{ data.item.name }}
        </template>
        <template
          #cell(deviceID)="data"
        >
          <feather-icon
            class="mr-1"
            :class="connectedVariant(data.item.lastUpdatedTime) ? 'text-success' : 'text-danger' "
            :fill="connectedVariant(data.item.lastUpdatedTime) ? '#28c76f' : '#ea5455' "
            icon="CircleIcon"
            size="20"
          />{{ data.item.deviceID }}
        </template>
        <template
          v-if="hideCustomer"
          #cell(customerName)="data"
        >
          {{ data.item.customerName }}
        </template>
        <template
          #cell(whatsapp)="data"
        >
          <b-icon-check-square-fill
            v-if="data.item.whatsapp"
            variant="success"
          />
          <b-icon-check-square-fill
            v-else
            variant="light"
          />
        </template>
        <template
          #cell(action)="data"
        >
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="choose-button"
                @click="selectedItem=data.item; isEditNewAssetSidebarActive = true"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>Edit Asset</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-twin
                class="choose-button"
                @click="selectedItem=data.item;showModal()"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>Edit Twin</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="choose-button"
                @click="detachDevice(data.item.assetID)"
              >
                <b-icon
                  icon="box-arrow-right"
                  class="mr-50"
                />
                <span>Detach Device</span>
              </b-dropdown-item>
              <b-dropdown-item>
                <b-link
                  :to="{ name: 'apps-assets-history', params: { 'assetID': data.item.assetID } }"
                >
                  <b-icon
                    icon="clock-history"
                    class="mr-50"
                  />
                  <span>History</span>
                </b-link>
              </b-dropdown-item>
              <!-- <b-dropdown-item>
                <feather-icon
                  icon="WifiIcon"
                  class="mr-50"
                />
                <span>Connect</span>
              </b-dropdown-item> -->
              <!-- <b-dropdown-item
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-asset
              >
                <feather-icon
                  icon="ArchiveIcon"
                  class="mr-50"
                />
                <span>Archive</span>
              </b-dropdown-item> -->
            </b-dropdown>
          </span>
        </template>
      </b-table>
      <b-modal
        id="modal-asset"
        ok-title="Save"
        cancel-title="Close"
        cancel-variant="outline-secondary"
        ok-variant="primary"
        centered
        size="lg"
        @show="loadModal"
        @hidden="resetModal"
        @ok="handleOk"
      >
        <json-editor-vue
          v-model="jsonEditorData"
          v-bind="editorOptions"
        />
      </b-modal>
      <b-modal
        ref="modal-twin"
        hide-footer
        centered
        size="lg"
      >
        <template #modal-header>
          <b-row
            fluid="lg"
            lg="12"
          >
            <b-col
              lg="10"
              class="mt-1"
            >
              {{ `Editing Asset ${selectedTwin.assetID} / ${selectedTwin.deviceID}` }}
            </b-col>
            <b-col
              lg="2"
              class="mt-1"
            >
              <b-link
                href="https://github.com/elevate-tech-sg/factory-documentation/blob/0ab21b4b28f0f0f21bf7f20229a53a1cac452b2d/et-platform-documentation/docs/device.md"
                target="_blank"
              >
                <b-icon
                  icon="info-circle"
                  class="mr-50"
                />
              </b-link>
            </b-col>
          </b-row>
        </template>
        <div class="d-block">
          <json-editor-vue
            v-model="selectedTwinData"
            v-bind="editorOptions"
          />
        </div>
        <validation-observer ref="simpleRules">
          <b-form>
            <!-- button on right -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Reason for update"
                rules="required"
              >
                <b-row
                  fluid="lg"
                  lg="12"
                >
                  <b-col
                    lg="8"
                    class="mt-1"
                  >
                    <b-form-input
                      id="reasonForUpdate"
                      v-model="selectedTwin.reasonForUpdate"
                      autofocus
                      trim
                      placeholder="Reason for update"
                      label="Name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-col>
                  <b-col
                    lg="2"
                    class="mt-1"
                  >
                    <b-button
                      variant="primary"
                      @click.prevent="saveTwin"
                    >
                      Save
                    </b-button>
                  </b-col>
                  <b-col
                    lg="2"
                    class="mt-1"
                  >
                    <b-button
                      variant="secondary"
                      @click="hideModal"
                    >
                      Cancel
                    </b-button>
                  </b-col>
                </b-row>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BTable, BFormGroup, BInputGroup, BFormInput, BCardBody, BCard, BButton, BDropdown, BDropdownItem, BModal, BRow, BCol, BForm, BLink, BMedia, BFormSelect, BFormSelectOption, BIcon, BIconBoxArrowRight, BBadge, BIconClockHistory, BIconInfoCircle, BIconCheckSquareFill, BIconXSquareFill,
} from 'bootstrap-vue'
import {
  allowAssetManagement,
} from '@/api/auth/utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import store from '@/store/index'
// eslint-disable-next-line import/no-cycle
import { isConnected } from '@core/utils/filter'
import { onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import JsonEditorVue from 'json-editor-vue'
import AssetListAddNew from './AssetListAddNew.vue'
import AssetListEdit from './AssetListEdit.vue'
import assetsLogStore from '../assetsLogStore'

export default {
  components: {
    BButton,
    BCard,
    BTable,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BModal,
    AssetListAddNew,
    AssetListEdit,
    BRow,
    BCol,
    BLink,
    BMedia,
    BFormSelect,
    BFormSelectOption,
    JsonEditorVue,
    ValidationProvider,
    ValidationObserver,
    BIcon,
    BIconBoxArrowRight,
    BIconClockHistory,
    BIconInfoCircle,
    BBadge,
    BIconCheckSquareFill,
    BIconXSquareFill,
  },
  directives: {
    Ripple,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-assets-list'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, assetsLogStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
  },
  data() {
    return {
      required,
      buildings: [],
      hideCustomer: false,
      lines: [],
      selectedItem: {},
      selectedTwin: {},
      selectedTwinData: {},
      isAddNewAssetSidebarActive: false,
      isEditNewAssetSidebarActive: false,
      allowAssetManagement: allowAssetManagement(),
      filter: null,
      filterOn: [],
      jsonEditorData: {},
      existingTwin: {},
      newTwin: {},
      editorOptions: {
        mode: 'text',
        mainMenuBar: false,
      },
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'customerName', label: 'Customer', sortable: true, filterable: true, filter: 'string',
        },
        {
          key: 'siteName', label: 'Site', sortable: true, filterable: true, filter: 'string',
        },
        {
          key: 'buildingName', label: 'Building', sortable: true, filterable: true, filter: 'string',
        },
        {
          key: 'lineName', label: 'Line', sortable: true, filterable: true, filter: 'string',
        },
        {
          key: 'assetID', label: 'ID', sortable: true, filterable: true, filter: 'number',
        },
        {
          key: 'name', label: 'Asset', sortable: true, filterable: true, filter: 'string',
        },
        {
          key: 'deviceID', label: 'Device ID', sortable: true, filterable: true, filter: 'string',
        },
        {
          key: 'whatsapp', label: 'WhatsApp Notification', sortable: true, filterable: true, filter: 'boolean',
        },
        {
          key: 'action', label: 'Action', sortable: false, filterable: false, visible: this.allowAssetManagement,
        },
      ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  mounted() {
    if (!this.allowAssetManagement) {
      const action = this.fields.find(({ key }) => key === 'action')
      // remove from fields
      this.fields.splice(this.fields.indexOf(action), 1)
    }
    this.fetchAllAssets()
  },
  methods: {
    statusMapping(status) {
      let val = 'seconday'
      if (status === 0) {
        val = 'dark'
      } else if (status === 2) {
        val = 'success'
      } else if (status === 1) {
        val = 'warning'
      } else if (status === 3) {
        val = 'danger'
      } else if (status === -1) {
        val = 'white'
      }
      return val
    },
    statusMappingColor(status) {
      let val = 'white'
      if (status === 0) {
        val = '#4b4b4b'
      } else if (status === 2) {
        val = '#28c76f'
      } else if (status === 1) {
        val = '#ff9f43'
      } else if (status === 3) {
        val = '#ea5455'
      } else if (status === -1) {
        val = 'white'
      }
      return val
    },
    resetModal() {
      this.jsonEditorData = null
    },
    loadModal() {
      // Copy item to selectedItem
      this.jsonEditorData = JSON.parse(JSON.stringify(this.selectedItem))
      delete this.jsonEditorData.customerID
      delete this.jsonEditorData.id
      delete this.jsonEditorData.assetID
      delete this.jsonEditorData.siteName
      delete this.jsonEditorData.buildingName
      delete this.jsonEditorData.customerName
      delete this.jsonEditorData.lineName
      delete this.jsonEditorData.deviceID
      delete this.jsonEditorData.status
      delete this.jsonEditorData.archived
      delete this.jsonEditorData.state
      delete this.jsonEditorData.msEpoch
      if (this.jsonEditorData) {
        return true
      }
      return false
    },
    connectedVariant(val) {
      return isConnected(val)
    },
    handleOk() {
      this.updateAsset()
    },
    showModal() {
      // getTwin
      store
        .dispatch('app-assets-list/getTwin', this.selectedItem.assetID)
        .then(response => {
          this.selectedTwin = response.data
          const { twin } = this.selectedTwin
          delete twin.assetID
          this.selectedTwinData = twin
          this.$refs['modal-twin'].show()
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching twin info',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    hideModal() {
      this.selectedItem = {}
      this.selectedTwin = {}
      this.selectedTwinData = {}
      this.$refs['modal-twin'].hide()
    },
    saveTwin() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const twin = JSON.parse(this.selectedTwinData)
          twin.assetID = this.selectedTwin.assetID
          const twinData = {
            assetID: this.selectedTwin.assetID,
            deviceID: this.selectedTwin.deviceID,
            twin,
            reasonForChange: this.selectedTwin.reasonForUpdate,
          }
          console.log(twinData)
          store
            .dispatch('app-assets-list/updateTwin', twinData)
            .then(response => {
              console.log(response)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Twin updated successfully',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.hideModal()
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error fetching assets list',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    fetchAllAssets() {
      store
        .dispatch('app-assets-list/fetchAllAssets')
        .then(response => {
          this.items = response.data
          // sort by assetID
          this.items.sort((a, b) => a.assetID - b.assetID)
          this.lines = [...new Set(this.items.map(a => a.lineName))]
          this.buildings = [...new Set(this.items.map(a => a.buildingName))]
          this.hideCustomer = !([...new Set(this.items.map(a => a.customerName))].length > 1)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching assets list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    updateAsset() {
      // update contents of selectedItem with jasonEditorData
      console.log(this.jsonEditorData)
      const data = Object.assign(this.selectedItem, JSON.parse(this.jsonEditorData))
      console.log(data)

      store
        .dispatch('app-assets-list/updateAsset', data)
        .then(() => {
          this.fetchAllAssets()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Asset updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating asset',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.fetchAllAssets()
        })
    },

    detachDevice(assetID) {
      store.dispatch('app-assets-list/getTwin', assetID)
        .then(response => {
          this.existingTwin = response.data
          this.newTwin = { ...this.existingTwin }
          this.saveNewTwin()
        })
        .catch(() => {
          this.isSubmitting = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error Retrieving Twin Information',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    saveNewTwin() {
      this.newTwin.reasonForChange = 'Return and Detach Device'
      this.newTwin.newDeviceID = 'None'
      console.log(this.newTwin)
      store.dispatch('app-assets-list/swapDevice', this.newTwin)
        .then(() => {
          this.isSubmitting = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Detached the device successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.fetchAllAssets()
        })
        .catch(() => {
          this.isSubmitting = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error in detaching Device',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
